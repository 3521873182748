import { NotificationType } from '../../enums/notification-type.enum';
import { ObjectUtil } from '../../utility/object.util';
import { BaseNotificationModel } from './base-notification.model';

export class LastSalesNotificationModel extends BaseNotificationModel {
    public rep: string;
    public city: string;
    public value: string;

    public constructor(data?: any, type?: NotificationType) {
        super(data);
        ObjectUtil.extend(data, this);
        this.type = type;
    }
}