@if (mobile) {
@if (isAuthorize) {
<lgfg-header-mobile [profileSettingsModal]="profileSettingsModal"></lgfg-header-mobile>
<notification [isMobile]="true"></notification>
}
<main>
    <router-outlet></router-outlet>
</main>
} @else {
<div class="site-wrap">
    <div class="site-wrap__container">
        <div class="site-wrap__sidebar">
            @if (isAuthorize) {
            <sidebar-desktop (openSideBar)="onOpenSideBar($event)"></sidebar-desktop>
            }
        </div>
        <div style="width: 100%;">
            @if (isAuthorize) {
            <div class="site-wrap__header">
                <lgfg-header-desktop [profileSettingsModal]="profileSettingsModal"></lgfg-header-desktop>
            </div>
            }
            <div [ngStyle]="{'width': bodyMaxWidth + 'px'}" style="overflow: hidden;">
                <main>
                    <router-outlet></router-outlet>
                </main>
            </div>
        </div>
    </div>
</div>
}
<profile-settings-modal #profileSettingsModal></profile-settings-modal>