<div class="bar-container-wrap">
    <div class="bar-container bg-color">
        <div class="row title">
            <div style="width: 70px; margin-right: 4px;" class="col-1">
                <img src="assets/start-page/our-goal.png" class="logo-start-page">
            </div>
            <div class="col" style="padding-top: 3px;">
                <div class="row chart-title-text-1 text-color">
                    Our goal
                </div>
                <div class="row chart-title-text-2 title-color">
                    ${{current}}
                </div>
            </div>
        </div>
        <div class="goal-container margin-bottom-2rem margin-top-2rem" class="row">
            <div class="row  margin-top-4">
                <span class="progress-label text-color"
                    style="transform: translateY(-42px);margin-left: 162px;">{{firsPoint}}</span>
                <span class="progress-label text-color"
                    style="transform: translateY(-65px);margin-left: 286px;">{{secondPoint}}</span>
                <span class="progress-label text-color"
                    style="transform: translateY(-89px);margin-left: 406px;">{{thirdPoint}}</span>
                <div class="w-line" id="first-line"></div>
                <div class="w-line" id="second-line"></div>
                <div class="w-line" id="third-line"></div>
                <div class="w-line-mob" style="transform: translateX(380px);"></div>
            </div>
            <div class="row progress-container">
                <div style="transform: translate(20px,-3px);" class="col-1 text-color">
                    $0
                </div>
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            style="background-color: #9060F8;" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                            [ngStyle]="{'width': progress+'%'}"></div>
                    </div>
                </div>
                <div class="final-point col-1 text-color">
                    ${{goal}}
                </div>
            </div>
        </div>
    </div>
    <div class="bar-container bg-color">
        <div class="row title">
            <div style="width: 70px; margin-right: 4px;" class="col-1">
                <img src="assets/start-page/sales-proggres.png" class="logo-start-page">
            </div>
            <div class="col" style="padding-top: 3px;">
                <div class="row chart-title-text-1 text-color">
                    My Goal
                </div>
                <div class="row chart-title-text-2 title-color">
                    ${{userSales}}
                </div>
            </div>
        </div>
        <div class="goal-container margin-bottom-2rem margin-top-2rem" class="row">
            <div class="row  margin-top-4">
                <span class="progress-label text-color"
                    style="transform: translateY(-42px);margin-left: 162px;">{{firsPointUserGoal}}K</span>
                <span class="progress-label text-color"
                    style="transform: translateY(-65px);margin-left: 286px;">{{secondPointUserGoal}}K</span>
                <span class="progress-label text-color"
                    style="transform: translateY(-89px);margin-left: 406px;">{{thirdPointUserGoal}}K</span>
                <div class="w-line" id="first-line"></div>
                <div class="w-line" id="second-line"></div>
                <div class="w-line" id="third-line"></div>
                <div class="w-line-mob" style="transform: translateX(380px);"></div>
            </div>
            <div class="row progress-container">
                <div style="transform: translate(20px,-3px);" class="col-1 text-color">
                    $0
                </div>
                <div class="col">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            style="background-color: #FFAE15;" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                            [ngStyle]="{'width': userSalesProgress+'%'}"></div>
                    </div>
                </div>
                <div class="final-point col-1 text-color">
                    {{userGoal}}
                </div>
            </div>
        </div>
    </div>
</div>