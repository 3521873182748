<modal [ngClass]="isDarkTheme ? 'dark-theme':'light-theme'" #modal modalTitle="Profile Settings"
    [isInformational]="true" [displayLaunchButton]="false" (secondaryClick)="resetModal()"
    (onCompleteAction)="resetModal()" (primaryClick)="resetModal()" primaryBtnTextKey="Close"
    [loadingIndicator]="isProcessing">
    <div #AvatarContainer=ControlContainerDirective ControlContainer class="avatar-container">
        @if(user?.avatarUrl){
        <img [src]="user?.avatarUrl" alt="Avatar" class="avatar" />
        }
        <file-selector [btnText]="user?.avatarUrl ? 'Replace': 'Add' " style="margin-top: 20px; margin-right: 16px;"
            (change)="updateAvatar()" label="Profile picture" [(ngModel)]="file" [reset]="reset" [FileFormat]="imageFileTypes"
            required></file-selector>
        @if(user?.avatarUrl)
        {
        <button style="margin-top: 30px;" (click)="removeAvatar()" class="btn-reset">Remove</button>
        }
    </div>
    <div #PasswordContainer=ControlContainerDirective ControlContainer class="password-container">
        <div style="width:321px">
            <LGFG-input label="New password" placeholder="New password" [(ngModel)]="password" required></LGFG-input>
        </div>
        <div style="width:153px">
            <button style="margin-left: 8px; margin-top: 34px;" (click)="updatePassword()"
                class="btn-submit">Update</button>
        </div>
    </div>
</modal>