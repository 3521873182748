import { GoalService } from '../../core/service/goal.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateUtil } from 'src/app/core/utility/date.util';
import { FormattingUtil } from 'src/app/core/utility/formatting.util';

@Component({
    selector: 'progress-bar-panel',
    templateUrl: './progress-bar-panel.component.html',
    styleUrls: ['./progress-bar-panel.component.scss'],
    standalone: true,
    imports: [CommonModule]
})

export class ProgressBarPanelComponent implements OnInit {
    public progress = 0;
    public current;
    public goal = '0k';
    public firsPoint = '0k';
    public secondPoint = '0k';
    public thirdPoint = '0k';
    public firsPointUserGoal = 0;
    public secondPointUserGoal = 0;
    public thirdPointUserGoal = 0;
    public userSalesProgress;
    public userSales;
    public userGoal = "";

    public constructor(private goalService: GoalService) { }

    async ngOnInit(): Promise<void> {
        const result = await this.goalService.getOurGoals();
        const goal = result.items.find(x => x.year === DateUtil.currentYear);
        if (goal) {
            this.progress = goal.current / goal.goal * 100;
            this.current = FormattingUtil.getCommaSeparatedNumber(goal.current);
            if (goal.goal >= 10000000) {
                this.goal = (goal.goal / 1000000) + "M";
                this.firsPoint = ((goal.goal * 0.25) / 1000000) + "M";
                this.secondPoint = ((goal.goal * 0.5) / 1000000) + "M";
                this.thirdPoint = ((goal.goal * 0.75) / 1000000) + "M";
            }
            else {
                this.goal = (goal.goal / 1000) + "K";
                this.firsPoint = ((goal.goal * 0.25) / 1000) + "K";
                this.secondPoint = ((goal.goal * 0.5) / 1000) + "K";
                this.thirdPoint = ((goal.goal * 0.75) / 1000) + "K";
            }
        }

        const userSalesProgress = await this.goalService.getUserProgress();
        const userGoal = await this.goalService.getMyGoal();
        if (userGoal >= 1000000) {
            this.userGoal = "$" + userGoal / 1000000 + "M";
        }
        else {
            this.userGoal = "$" + userGoal / 1000 + "K";
        }
        this.userSales = FormattingUtil.getCommaSeparatedNumber(userSalesProgress);
        this.firsPointUserGoal = (userGoal * 0.25) / 1000;
        this.secondPointUserGoal = (userGoal * 0.5) / 1000;
        this.thirdPointUserGoal = (userGoal * 0.75) / 1000;
        this.userSalesProgress = userSalesProgress / userGoal * 100;
    }
}
