import { EventEmitter, Injectable } from '@angular/core';
import { LastSalesNotificationModel } from '../models/notification/last-salse-notification.model';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { LastSurveyNotificationModel } from '../models/notification/last-survey-notification.model';
import { NotificationType } from '../enums/notification-type.enum';

@Injectable()
export class NotificationService extends BaseService {
    public updateLastSales: EventEmitter<void> = new EventEmitter<void>();

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getLastSales(): Promise<LastSalesNotificationModel[]> {
        const data = await this.handleResponse(this.restService.get({
            url: `/notification/get-last-sales/`
        }));

        return data.map(x => new LastSalesNotificationModel(x, NotificationType.LastSales));
    }

    public async getSurveyNotifications(): Promise<LastSurveyNotificationModel[]> {
        const data = await this.handleResponse(this.restService.get({
            url: `/notification/get-last-survey/`
        }));

        return data.map(x => new LastSurveyNotificationModel(x, NotificationType.LastSurvey));
    }
}
