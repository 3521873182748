import { BaseReportGuard } from './core/guard/base-report.guard';
import { NotFoundPageComponent } from './error-pages/notfound/notfound.component';
import { AccessDeniedPageComponent } from './error-pages/access-denied/access-denied.component';
import { StartPageComponent } from './start-page/start-page.component';
import { Routes } from '@angular/router';
import { links } from './core/links';
import { MyGoalPageComponent } from './my-goal-page/my-goal-page.component';

export const ROUTES: Routes = [
  {
    path: links.reportLink,
    loadChildren: () => import('./reports/report.routes').then(r => r.ROUTES)
  },
  {
    path: links.submitPaymentLink,
    loadChildren: () => import('./submit-payment/submit-payment.routes').then(r => r.ROUTES)
  },
  {
    path: links.surveyResultLink,
    loadChildren: () => import('./survey-result/survey-result.routes').then(r => r.ROUTES)
  },
  {
    path: links.documentsLink,
    loadChildren: () => import('./documents/document.routes').then(r => r.ROUTES)
  },
  {
    path: links.appointmentCardLink,
    loadChildren: () => import('./appointment-card/appointment-card.routes').then(r => r.ROUTES)
  },
  {
    path: links.stratPage,
    component: StartPageComponent
  },
  {
    path: links.loginLink,
    loadChildren: () => import('./login/login.routes').then(r => r.ROUTES)
  },
  {
    path: links.errors.error403,
    component: AccessDeniedPageComponent
  },
  {
    path: links.errors.error404,
    component: NotFoundPageComponent
  },
  {
    path: links.adminSettingsLink,
    loadChildren: () => import('./admin-settings/admin-settings.routes').then(r => r.ROUTES)
  },
  {
    path: links.myGoal,
    component: MyGoalPageComponent,
    canActivate: [BaseReportGuard]
  }
];
