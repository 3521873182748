<modal #modal ControlContainer modalTitle="Setup Your Yearly Goal" launchButtonText="Create New Goal"
    (secondaryClick)="resetModal()" (onCompleteAction)="resetModal()" (primaryClick)="onSubmit()"
    [loadingIndicator]="isProcessing">
    <div class="row">
        <div class="col">
            <LGFG-input placeholder="Write year..." type="number" label="Year" [(ngModel)]="myGoal.year"
                required></LGFG-input>
        </div>
    </div>
    <div class="row margin-top-2">
        <div class="col">
            <LGFG-input placeholder="Write goal..." type="number" label="Goal" [(ngModel)]="myGoal.goal"
                required></LGFG-input>
        </div>
    </div>
    @if(isAdmin)
    {
    <div class="row margin-top-2" style="margin-bottom: 340px;">
        <div class="col">
            <reporter-autocomplete (onFiltered)="myGoal.userId= $event;" [checkedByDefault]="myGoal.userId"
                [reset]="reset" label="User" required></reporter-autocomplete>
        </div>
    </div>
    }
</modal>