import { LastSalesNotificationModel } from '../../core/models/notification/last-salse-notification.model';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
    HostListener, Input, OnInit, ViewChild
} from '@angular/core';
import { CommonModule, NgClass, NgStyle } from '@angular/common';
import { GlobalConstants } from 'src/app/core/global-constants';
import { UserContextService } from 'src/app/core/service/user-context.service';
import { NotificationService } from 'src/app/core/service/notification.service';
import { LastSurveyNotificationModel } from 'src/app/core/models/notification/last-survey-notification.model';
import { BaseNotificationModel } from 'src/app/core/models/notification/base-notification.model';
import { ArrayUtil } from 'src/app/core/utility/array.util';
import { DateUtil } from 'src/app/core/utility/date.util';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, NgStyle, NgClass]
})

export class NotificationComponent implements OnInit {
    @Input() public isMobile: boolean = false;

    @ViewChild("dropdownBody", { static: true }) public dropdownBody: any;

    public lastNotification: LastSalesNotificationModel[] | LastSurveyNotificationModel[];
    public missingNotifications: string = "0";
    public isOpen: boolean = false;
    public isDarkTheme: boolean = false;

    public constructor(
        private service: NotificationService,
        private userContextService: UserContextService,
        private ref: ChangeDetectorRef) { }

    async ngOnInit(): Promise<void> {
        const notifications = (await this.service.getLastSales()).concat(await this.service.getSurveyNotifications() as any);
        this.lastNotification = ArrayUtil.orderByDesc(notifications, "eventDate");
        this.setMissingNotifications();

        this.service.updateLastSales.subscribe(async () => {
            const notifications = (await this.service.getLastSales()).concat(await this.service.getSurveyNotifications() as any);
            this.lastNotification = ArrayUtil.orderByDesc(notifications, "eventDate");
            this.setMissingNotifications();
        });

        this.userContextService.themeState.subscribe((x) => {
            this.isDarkTheme = x;
            this.ref.detectChanges();
        });
        setInterval(async () => {
            const notifications = (await this.service.getLastSales()).concat(await this.service.getSurveyNotifications() as any);
            this.lastNotification = ArrayUtil.orderByDesc(notifications, "eventDate");
            this.setMissingNotifications();
        }, 60000);
    }

    private setMissingNotifications(): void {
        const storedNotifications: BaseNotificationModel[] = JSON.parse(localStorage.getItem(GlobalConstants.viewedNotificationsKey));
        if (!storedNotifications || storedNotifications.length == 0) {
            this.missingNotifications = this.lastNotification.length.toString();
        }
        else {
            this.missingNotifications = this.lastNotification
                .filter(x => storedNotifications.every(y => y.id != x.id)).length.toString();
        }
        if (Number(this.missingNotifications) > 9) {
            this.missingNotifications = "9+";
        }
        this.ref.detectChanges();
    }

    public toggle(): void {
        setTimeout(() => {
            if (!this.isOpen) {
                this.missingNotifications = "0";
                localStorage.setItem(GlobalConstants.viewedNotificationsKey, JSON.stringify(this.lastNotification));
            }
            this.isOpen = !this.isOpen;
            this.ref.detectChanges();
        }, 70);
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: ElementRef) {
        if (!this.isOpen) {
            return;
        }
        const clickedInside = this.dropdownBody.nativeElement.contains(targetElement) ||
            document.getElementsByClassName('icon-body')[0].contains(targetElement as any)
        if (!clickedInside) {
            this.isOpen = false;
            this.ref.detectChanges();
        }
    }

    public identify(index, item) {
        return item.orderId;
    }
}
