import { of, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import merge from 'lodash-es/merge';

import en from './en.json';
import termsEn from './terms.en.json';

export class TranslateFileLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
        return of(merge({}, termsEn, en));
    }
}
