import { NotificationType } from "../../enums/notification-type.enum";
import { DateUtil } from "../../utility/date.util";
import { ObjectUtil } from "../../utility/object.util";

export class BaseNotificationModel {
    public id: number;
    public type: NotificationType;
    public eventDate: Date;

    constructor(data?: any) {
        ObjectUtil.extend(data, this, {
            eventDate: DateUtil.fromString
        });
    }
}