import { ChartService } from './../core/service/chart.service';
import { AppRouterService } from '../core/service/app-router.service';
import { UserContextService } from './../core/service/user-context.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, } from "@angular/core";
import { ChartDataset } from 'chart.js';
import sum from "lodash-es/sum";
import { FormattingUtil } from '../core/utility/formatting.util';
import { ProgressBarPanelComponent } from './progress-bar-panel/progress-bar-panel.component';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BarChartComponent } from '../shared/base-charts/bar-chart/bar-chart.component';
import { TopSalesModel } from '../core/models/charts/top-sales.model';

@Component({
    selector: 'start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss'],
    standalone: true,
    imports: [CommonModule, ProgressBarPanelComponent, NgxSpinnerModule, BarChartComponent]
})
export class StartPageComponent implements OnInit, AfterViewInit {    
    @ViewChild('firstCarouselItem') set content(content: ElementRef) {
        if (content) {
            content.nativeElement.click();
        }
    }

    public sourceType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';

    public videos = [
        {
            id: 0,
            poster: '/assets/start-page/videos/posters/Jordan Peterson.png',
            source: '/assets/start-page/videos/JP Twitter suit.mp4',
            isVideoLoaded: false,
            isActive: true
        },
        {
            id: 1,
            poster: 'assets/start-page/videos/posters/DanLok.png',
            source: 'assets/start-page/videos/Dan Lok Gentlemen.mp4',
            isVideoLoaded: false
        },
        {
            id: 2,
            poster: 'assets/start-page/videos/posters/rectangle11.png',
            source: 'assets/start-page/videos/Alice5QB.mp4',
            isVideoLoaded: false
        },
        {
            id: 3,
            poster: 'assets/start-page/videos/posters/rectangle15.png',
            source: 'assets/start-page/videos/lv_0_20230203181531.mp4',
            isVideoLoaded: false
        },
        {
            id: 4,
            poster: 'assets/start-page/videos/posters/Zuby.png',
            source: 'assets/start-page/videos/79E2CBE9-DA57-4863-B988-A27B90CA410B.mp4',
            isVideoLoaded: false
        },
        {
            id: 5,
            poster: 'assets/start-page/videos/posters/Tommy.png',
            source: 'assets/start-page/videos/IMG_4352.mp4',
            isVideoLoaded: false
        },
        {
            id: 6,
            poster: 'assets/start-page/videos/posters/Martyn.png',
            source: 'assets/start-page/videos/lv_0_20230830140916.mp4',
            isVideoLoaded: false
        },
        {
            id: 7,
            poster: 'assets/start-page/videos/posters/pos5.png',
            source: 'assets/start-page/videos/IMG_4614.mp4',
            isVideoLoaded: false
        },
        {
            id: 8,
            poster: 'assets/start-page/videos/posters/AJ.png',
            source: 'assets/start-page/videos/original-14042584-1701-4DD7-B646-AE2F95397B85.mp4',
            isVideoLoaded: false
        },
        {
            id: 9,
            poster: 'assets/start-page/videos/posters/image 1.png',
            source: 'assets/start-page/videos/12 buffer get to work.mp4',
            isVideoLoaded: false
        },
        {
            id: 10,
            poster: 'assets/start-page/videos/posters/1.png',
            source: 'assets/start-page/videos/07 Rob Schneider get to work.mp4',
            isVideoLoaded: false
        },
        {
            id: 11,
            poster: 'assets/start-page/videos/posters/Tia.png',
            source: 'assets/start-page/videos/09 Tia get to work.mp4',
            isVideoLoaded: false
        },
        {
            id: 12,
            poster: 'assets/start-page/videos/posters/Ozy.png',
            source: 'assets/start-page/videos/05 ozzy get to work.mp4',
            isVideoLoaded: false
        },
        {
            id: 13,
            poster: 'assets/start-page/videos/posters/Joe.png',
            source: 'assets/start-page/videos/02 joe dispenza get to work.mp4',
            isVideoLoaded: false
        },
        {
            id: 14,
            poster: 'assets/start-page/videos/posters/Robbie.png',
            source: 'assets/start-page/videos/14 trujillo get to work1.mp4',
            isVideoLoaded: false
        },
        {
            id: 15,
            poster: 'assets/start-page/videos/posters/Dolph.png',
            source: 'assets/start-page/videos/16 dolph get to work.mp4',
            isVideoLoaded: false
        }
    ]

    public chartLabels: string[];
    public chartData: ChartDataset[];
    public maxScale: number;
    public stepSize: number;
    public anyData: boolean = false;
    public repName: string;
    public repFullName: string;
    public chartSum: string;
    public chartHeight: number;
    public mobile = false;
    public isDarkTheme: boolean;
    public top3UsersData: TopSalesModel[];
    public top3UsersDisplayOrder = [1, 0, 2];
    public loaderImg: string;

    public constructor(
        private userContextService: UserContextService,
        private router: AppRouterService,
        private chartService: ChartService,
        private spinner: NgxSpinnerService) {
            document.title = "CMS 2.0";
            this.chartSum = "0";
            this.top3UsersData = [
                new TopSalesModel(),
                new TopSalesModel(),
                new TopSalesModel(),
            ];
        }

    public ngAfterViewInit(): void {
        this.userContextService.themeState.subscribe(x => {
            this.isDarkTheme = x;
            this.loaderImg = x ? "<img src='assets/crown.png'/>" : "<img style='transform: scale(1.6);' src='assets/crown-dark.png'/>";
        });
        setTimeout(() => {
            this.mobile = window.outerWidth < 600;
        }, 500);
    }

    public async ngOnInit(): Promise<void> {
        window.scrollTo(0, 0);
        if (this.userContextService.isAuthorize) {
            this.router.getDefaultLink();
            this.spinner.show();
            this.repFullName = this.userContextService.user.value.displayName;
            this.repName = this.repFullName.split(' ')[0];
        }
        else {
            this.router.openLogin();
        }

        var exclude = ['Yoana Zarkova', 'Levi EA', 'companysecretary', 'Mariia Kosenko', 'Joseph Otoo', 'Repo Account', 'Repo1 Account', 'Repo2 Account', 'Repo3 Account', 'Repo4 Account'
            , 'Test Admin', 'Test Clotheirt', 'Õnne Tera', 'Stoyan Ivanov', 'Julia Grishina', 'Ave Uusleer', 'Mamikon Hadojev', 'Kersti Sulamagi', 'J Dial', 'Dimitry Toukhcher', 'Anly Renda',
            "Hope Sun", "Mikhail Stromov", "Dominika Poczciwek", "LGFG System QA", "test dealer account", "Vladimir Gubarev", "Test Clothier", "Test Clothier",
            "OperationManager", "SystemAdmin", "Test ClothierTrainer", "Test OrderPorter", "Viktoria Kropyvko", "Aleksandra Wilson", "Henri Nomm", "Rosanna Lints"];

        this.top3UsersData = await this.chartService.getTop3Users();
        let chart = await this.chartService.getMainChart();
        this.anyData = chart.length > 0;
        chart = chart.filter(x => !exclude.some(y => y == x.itemLabel));
        const data = chart.map(x => x.itemValue);
        this.chartSum = FormattingUtil.getCommaSeparatedNumber(sum(data).toFixed(2));
        this.maxScale = Math.round(Math.max(...data));
        this.chartHeight = data.length * 20;
        this.stepSize = this.maxScale > 50000 ? 10000 : this.maxScale > 10000 ? 5000 : this.maxScale > 5000 ? 1000 : 200;
        this.chartLabels = chart.map(x => x.itemLabel);
        const chartCurrentUserIndex = this.chartLabels.findIndex(x => x == this.repFullName);
        this.chartData = [
            {
                label: 'CAD $$$',
                data: data,
                backgroundColor: (context) => {
                    const index = context.dataIndex;
                    return index === chartCurrentUserIndex ? '#FFAE15' : '#00C896';
                },
                borderColor: (context) => {
                    const index = context.dataIndex;
                    return index === chartCurrentUserIndex ? '#FFAE15' : '#00C896';
                },
                borderWidth: 1,
            }
        ];
    }

    public onPosterClick(id: number): void {
        this.videos.find(x => x.id == id).isVideoLoaded = true;        
        this.videos.filter(x => x.id != id).forEach(x => x.isVideoLoaded = false);
    }

    public onVideoEnded(id: number): void {
        this.videos.find(x => x.id == id).isVideoLoaded = false;
    }

    public textFormater(value: number): string {
        const result = value / 1000;
        if (result < 1) {
            return value.toString();
        }
        else {
            return result.toFixed() + 'k';
        }
    }

    public pauseAllVideos(): void {
        this.videos.forEach(x => x.isVideoLoaded = false);
    }

    public onVideoClick(element): void {
        if (element.paused) {
            element.play();
        }
        else {
            element.pause();
        }
    }

    public top3Identify(index, item) {
        return item;
    }

    public videoIdentify(index, item) {
        return item.id;
    }
}
