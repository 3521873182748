import { Component, OnInit } from "@angular/core";
import { AppRouterService } from "src/app/core/service/app-router.service";
import { LoginService } from "src/app/core/service/login.service";
import { UserContextService } from "src/app/core/service/user-context.service";

@Component({
    selector: 'access-denied-page',
    templateUrl: './access-denied.component.html',
    standalone: true,
})
export class AccessDeniedPageComponent implements OnInit {
    public isAuthorize = true;

    public constructor(
        private loginService: LoginService,
        private appRouter: AppRouterService,
        private context: UserContextService) { }

    public async ngOnInit(): Promise<void> {
        var verifyResult = await this.loginService.verifyUser();
        if (!verifyResult) {
            this.context.resetContext();
            this.appRouter.openLogin();
        }
    }
}
