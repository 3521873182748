import { ObjectUtil } from "../../utility/object.util";

export class TopSalesModel {
    public name: string;
    public salse: number;
    public avatarUrl: string;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}
