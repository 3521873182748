import { LoginService } from 'src/app/core/service/login.service';
import { enableProdMode, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateFileLoader } from './app/core/i18n/i18n.translate-loader.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { InterceptorService } from './app/core/service/interseptor.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpRestService } from './app/core/service/http-client/http-rest-client.service';
import { ErrorHandlerService } from './app/core/service/http-client/error-handler.service';
import { AppRouterService } from './app/core/service/app-router.service';
import { UserContextService } from './app/core/service/user-context.service';
import { LocalizationService } from './app/core/service/localization.service';
import { BaseReportGuard } from './app/core/guard/base-report.guard';
import { ChartService } from './app/core/service/chart.service';
import { GoalService } from './app/core/service/goal.service';
import { ReportService } from './app/core/service/reports.service';
import { FormattingService } from './app/core/service/formatting.service';
import { SearchService } from './app/core/service/search.service';
import { ExportReportService } from './app/core/service/export-report.service';
import { TableService } from './app/core/service/table.service';
import { LogService } from './app/core/service/log.service';
import { AdminGuard } from './app/core/guard/admin.guard';
import { BookingBuddyReportGuard } from './app/core/guard/booking-buddy-report.guard';
import { ExpenseReportGuard } from './app/core/guard/expense-report.guard';
import { AlterationReportGuard } from './app/core/guard/alteration-report.guard';
import { MainRoleGuard } from './app/core/guard/main-role.guard';
import { CashflowTrailorsReportGuard } from './app/core/guard/сashflow-trailors-report.guard';
import { UserService } from './app/core/service/user.service';
import { ModalService } from './app/core/service/modal.service';
import { SalesReportGuard } from './app/core/guard/sales-report.guard';
import { UploadService } from './app/core/service/upload.service';
import { NotificationService } from './app/core/service/notification.service';
import { AccessService } from './app/core/service/access.service';
import { ToastService } from './app/core/service/toast.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(CommonModule, BrowserModule, AppRoutingModule, TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: TranslateFileLoader }
    })),
    provideHttpClient(withInterceptorsFromDi()),
    HttpRestService,
    AppRouterService,
    FormattingService,
    ReportService,
    SearchService,
    HttpRestService,
    LocalizationService,
    LoginService,
    ModalService,
    ToastService,
    UserService,
    UserContextService,
    ErrorHandlerService,
    ChartService,
    TableService,
    ExportReportService,
    GoalService,
    LogService,
    UploadService,
    NotificationService,
    AccessService,
    //Guard//////////////////
    AdminGuard,
    BaseReportGuard,
    ExpenseReportGuard,
    AlterationReportGuard,
    BookingBuddyReportGuard,
    MainRoleGuard,
    CashflowTrailorsReportGuard,
    SalesReportGuard,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    provideAnimations(),
  ]
})
  .catch(err => console.error(err));
