import { UserContextService } from './../../core/service/user-context.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ControlContainerDirective } from 'src/app/shared/form/form-control-container/control-container.directive';
import { Subject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { InputComponent } from 'src/app/shared/form/Input/input.component';
import { ControlAccessDirective } from 'src/app/shared/form/form-control-container/control-access.directive';
import { GoalService } from 'src/app/core/service/goal.service';
import { MyGoalModel } from 'src/app/core/models/my-goal.model';
import { ReporterAutocompleteComponent } from 'src/app/shared/form/autocomplete/reporter-autocomplete/reporter-autocomplete.component';

@Component({
    selector: 'my-goal-modal',
    templateUrl: './my-goal-modal.component.html',
    standalone: true,
    imports: [
        ModalComponent,
        ControlContainerDirective,
        InputComponent,
        FormsModule,
        ControlAccessDirective,
        ReporterAutocompleteComponent
    ],
})
export class MyGoalModalComponent implements OnInit {
    @Output() public update = new EventEmitter();

    @ViewChild("modal") modal: ModalComponent;
    @ViewChild(ControlContainerDirective, { static: true }) private container: ControlContainerDirective;

    public reset = new Subject();
    public myGoal = new MyGoalModel();
    public isProcessing = false;
    public isAdmin: boolean;

    constructor(private goalService: GoalService, private spinner: NgxSpinnerService, private userContextService: UserContextService) { }

    public ngOnInit(): void {
        this.container.markAsPristine();
        this.isAdmin = this.userContextService.isAdmin();
    }

    public open(item: MyGoalModel) {
        this.myGoal = item;
        this.modal.open();
    }

    public async onSubmit(): Promise<void> {
        if (!this.container.validate()) {
            return;
        }
        if (!this.myGoal.userId) {
            this.myGoal.userId = this.userContextService.user.value.id;
        }
        this.isProcessing = true;
        this.spinner.show();
        await this.goalService.saveMyGoal(this.myGoal);
        this.isProcessing = false;
        this.spinner.hide();

        this.update.emit();
        this.resetModal();
    }

    public resetModal() {
        this.myGoal = new MyGoalModel();
        this.reset.next(false);
        this.container.markAsPristine();
        this.modal.close();
    }
}
